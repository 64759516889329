import '../css/app.less';

// import './modules/sample-form';
import 'lite-youtube-embed';
import initTippy from './modules/init-tippy';
import './modules/lazysizes';
import './modules/modal';

import './modules/cookieBot';
import './modules/ie-banner';
import './modules/set-css-variables';
import './modules/scrollmagic';
import './modules/nav';
import './modules/autocomplete';
import './modules/smooth-scrolling';
import './modules/date-picker';
import './modules/forms';
import './modules/checkout';
import './modules/slider';
import './modules/birthstones';
import './modules/share-button';
import './modules/product-cards';

import './modules/global-cart';

import { mapsExists, initMaps } from './modules/google-maps';

import styles from './modules/json/raffi-snazzy-maps.json';

if (document.querySelector('.js-map') != null) {
  if (!mapsExists()) await initMaps(styles);
}

document.addEventListener('DOMContentLoaded', () => {
  initTippy();
});
